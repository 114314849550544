
import UserFooterNav from '@/components/userFooterNav.vue'
import NoneContent from '@/components/noneContent.vue'
import orderApi from '@/http/apis/order'
import { defineComponent, reactive, toRefs, onMounted, onActivated } from 'vue'
import { GetQuoteRes, GetQuoteOfflineListRes } from '@/types/responseType'
/**
 * 商城报价单状态
 * @param {string} status 报价单状态值
 * @param {string} statusName 报价单状态名称
 */
interface QuoteStatus {
  status: string
  statusName: string
}
// 线下报价单状态
const offlineStatusList = [
  {
    statusName: '全部',
    status: '',
  },
  {
    statusName: '超报价有效期',
    status: 'outTime',
  },
  {
    statusName: '报价完成',
    status: 'finish',
  },
]
export default defineComponent({
  name: 'Quote',
  components: {
    UserFooterNav,
    NoneContent,
  },
  setup() {
    let onPage = 1 // 商城报价单页码
    let offPage = 1 // 线下报价单页码
    let fbPage = 1 // 非标报价单
    const item = 10 // 每页条数
    let getList = 0 // 获取列表
    // let status = '' // 商城报价单状态
    const state = reactive({
      tabIndex: 0,
      statusList: [
        { status: '', statusName: '全部' },
        { status: 'quotation', statusName: '快速报价中' },
        { status: 'finish', statusName: '报价完成' },
        { status: 'close', statusName: '已终止' },
        { status: 'outTime', statusName: '超出报价有效期' },
      ] as QuoteStatus[], // 商城报价单状态列表
      status: '', // 商城报价单状态
      onOrderNo: '', // 商城报价单号
      onQuoteList: [] as GetQuoteRes[], // 商城报价单列表
      onLoading: false, // 商城loading状态
      onFinished: false, // 商城数据是否加载完成
      offlineStatus: '', // 线下报价单状态
      offOrderNo: '', // 线下报价单号
      offQuoteList: [] as GetQuoteOfflineListRes[], // 线下报价单列表
      offLoading: false, // 线下loading状态
      offFinished: false, // 线下数据是否加载完成
      loading: false, // 列表loading状态
      fbQuoteList: [], // 非标报价列表
    })

    /**
     * @description: 获取商城报价单列表
     */
    const loadListOnline = async () => {
      const { data } = await orderApi.getQuotationList({
        pageNum: onPage,
        pageSize: item,
        quotationNumber: state.onOrderNo,
        quotationStatus: state.status,
      })
      try {
        data.list && (state.onQuoteList = state.onQuoteList.concat(data.list))
        onPage * item >= data.total ? (state.onFinished = true) : (state.onFinished = false)
        onPage === 1 && (getList += 1)
      } catch (error) {
        console.log(error, '报价单列表错误')
      }
      // state.onLoading = false
      state.loading = false
    }
    /**
     * @description: 获取非标报价单列表
     */
    const loadListOnFb = async () => {
      const { data } = await orderApi.getFBQuotationList({
        pageNum: fbPage,
        pageSize: item,
        quotationNumber: state.onOrderNo,
        quotationStatus: state.status,
      })
      try {
        data.list && (state.fbQuoteList = state.fbQuoteList.concat(data.list))
        fbPage * item >= data.total ? (state.onFinished = true) : (state.onFinished = false)
        fbPage === 1 && (getList += 1)
      } catch (error) {
        console.log(error, '报价单列表错误')
      }
      // state.onLoading = false
      state.loading = false
    }
    /**
     * @description: 开具发票状态转化文字
     */
    const TransferState = (status: string) => {
      switch (status) {
        case 'quotation':
          return '快速报价中'
        case 'close':
          return '已终止'
        case 'finish':
          return '报价完成'
        case 'outTime':
          return '超出报价有效期'
      }
    }

    /**
     * @description: 获取线下报价单列表
     */
    const loadListOffline = async () => {
      const { data } = await orderApi.getQuotationOfflineList({
        pageNum: offPage,
        pageSize: item,
        status: state.offlineStatus,
        quotationNumber: state.offOrderNo,
      })
      // console.log(data, '线下报价单列表')
      try {
        data.quotationList && (state.offQuoteList = state.offQuoteList.concat(data.quotationList))
        offPage * item >= data.total ? (state.offFinished = true) : (state.offFinished = false)
        offPage === 1 && (getList += 1)
      } catch (error) {
        console.log(error)
      }
      state.loading = false
    }

    /**
     * @description: 列表加载更多
     */
    const quoteListLoad = (type: number) => {
      if (state.loading) {
        return
      }
      state.loading = true
      switch (type) {
        case 1:
          onPage += 1
          loadListOnline()
          break
        case 2:
          offPage += 1
          loadListOffline()
          break
        case 3:
          fbPage += 1
          loadListOnFb()
          break
      }
    }

    /**
     * @description: 报价单号搜索
     */
    const quoteSearch = (type: number) => {
      switch (type) {
        case 1:
          onPage = 1
          state.onQuoteList = []
          loadListOnline()
          break
        case 2:
          offPage = 1
          state.offQuoteList = []
          loadListOffline()
          break
        case 3:
          fbPage = 1
          state.fbQuoteList = []
          loadListOnFb()
          break
      }
    }

    /**
     * @description: 线下状态筛选
     */
    const offlineStatusClick = (name: string) => {
      state.offlineStatus = name
      offPage = 1
      state.offQuoteList = []
      loadListOffline()
    }

    /**
     * @description: tab栏切换
     */
    const tabClick = (name: number) => {
      state.tabIndex = name
    }

    /**
     * @description: 商城状态筛选
     */
    const statusClick = (name: string) => {
      state.status = name

      if (state.tabIndex === 0) {
        onPage = 1
        state.onQuoteList = []
        loadListOnline()
      } else if (state.tabIndex === 2) {
        fbPage = 1
        state.fbQuoteList = []
        loadListOnFb()
      }
    }
    onActivated(() => {
      if (getList < 3) {
        loadListOnline()
        loadListOffline()
        loadListOnFb()
      }
    })
    onMounted(() => {
      getList = 0
    })
    return {
      ...toRefs(state),
      offlineStatusList,
      quoteListLoad,
      quoteSearch,
      statusClick,
      offlineStatusClick,
      tabClick,
      TransferState,
    }
  },
})
