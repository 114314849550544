<template>
  <van-tabbar route class="comm-footer">
    <van-tabbar-item to="/quote">
      <template #icon="props">
        <svg class="iconMb" aria-hidden="true">
          <use v-show="!props.active" xlink:href="#mb-quotation-1" />
          <use v-show="props.active" xlink:href="#mb-quotation-2" />
        </svg>
      </template>
      报价单
    </van-tabbar-item>
    <van-tabbar-item to="/order">
      <template #icon="props">
        <svg class="iconMb" aria-hidden="true">
          <use v-show="!props.active" xlink:href="#mb-order-1" />
          <use v-show="props.active" xlink:href="#mb-order-2" />
        </svg>
      </template>
      订单
    </van-tabbar-item>
    <van-tabbar-item to="/">
      <template #icon="props">
        <svg class="iconMb" aria-hidden="true">
          <use v-show="!props.active" xlink:href="#mb-mine-1" />
          <use v-show="props.active" xlink:href="#mb-mine-2" />
        </svg>
      </template>
      个人中心
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
export default {
  name: 'UserFooterNav',
}
</script>
<style lang="less" scoped>
.comm-footer {
  .iconMb {
    width: 17px;
    height: 17px;
  }
}
</style>
